import React from 'react';
import { SummaryLoading } from '../elements/summaryLoading';
            
export class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            summary: props.summary,
            summaryTitle: props.summaryTitle,
        };
    }

    render() {
        if(this.state.error)
        {
            return <div>Error: {this.state.error}</div>
        }
        else if(!this.state.isLoaded)
        {
            return (
                <>
                    <SummaryLoading count="5"></SummaryLoading>
                </>
            );
        }
        else 
        {
            return (
                <section className="resume">
                    <h3 className="mt-4">{this.state.summaryTitle ? this.state.summaryTitle : "Summary" }</h3>
                    <hr className="bg-primary my-2" style={{backgroundColor: "red"}} />
                    <div dangerouslySetInnerHTML={{__html: this.state.summary}}></div>
                </section>
            );
        }
    }
};
