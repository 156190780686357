import moment from "moment";
import React from "react";
import { getLinks } from "../api/geLinks";
import { SettingsModel } from '../../settings';


export class MainFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            footerLeftIsLoaded: false,
            footerLeftNav: [],
            footerCenterIsLoaded: false,
            footerCenterNav: [],
            footerRightIsLoaded: false,
            footerRightNav: []
        };
    }
    componentDidMount() {
        getLinks("Footer Left")
            .then((links) => {
                this.setState({
                    footerLeftIsLoaded: true,
                    footerLeftNav: links
                });
            },
            (error) => {
                console.error(error);
                this.setState({
                    footerLeftIsLoaded: true,
                    //error
                });
            });
        getLinks("Footer Center")
            .then((links) => {
                this.setState({
                    footerCenterIsLoaded: true,
                    footerCenterNav: links
                });
            },
            (error) => {
                console.error(error);
                this.setState({
                    footerCenterIsLoaded: true,
                    //error
                });
            });
        getLinks("Footer Right")
            .then((links) => {
                this.setState({
                    footerRightIsLoaded: true,
                    footerRightNav: links
                });
            },
            (error) => {
                console.error(error);
                this.setState({
                    footerRightIsLoaded: true,
                    //error
                });
            });
    }

    generateFooterNav(items) {
        return (
            <>
            {items.map(item => (
                <span key={item.linkId}>
                    <li key={item.linkId} className="nav-item">
                        {
                            item.path === "#" ? 
                            <h5>{item.title}</h5> :
                            <a href={item.path} className={"nav-link "+item.className}>{item.title}</a>
                        }
                    </li>
                    {
                        item.children?.map((child) => (
                            <li key={child.linkId} className="nav-item">
                                <a href={child.path} className={"nav-link "+item.className}>{child.title}</a>
                            </li>
                        ))
                    }
                </span>
            ))}
            </>
        );
    }

    render() {
        const { error, footerLeftIsLoaded, footerLeftNav, footerCenterIsLoaded, footerCenterNav, footerRightIsLoaded, footerRightNav } = this.state;
        if(error) {
            return <div>Error: {error}</div>;
        } else if (!footerLeftIsLoaded || !footerCenterIsLoaded || !footerRightIsLoaded) {
            return <div>Loading....</div>;
        }else {
            return (
                <>
                    <footer className="min-vh-25 bg-dark text-light p-5">
                        <div className="container-lg container-fluid">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        {this.generateFooterNav(footerLeftNav)}
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        {this.generateFooterNav(footerCenterNav)}
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        {this.generateFooterNav(footerRightNav)}
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <a className="navbar-brand" href="/"><img src={SettingsModel.LogoLight} alt="Brandon Korous" height="40" /></a>
                                    <p className="text-white">
                                        {SettingsModel.Moto}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <div className="bg-dark py-4">
                        <hr />
                        <div className="footer-closing container-fluid">
                            <div className="row">
                                <span className="text-start text-primary col">
                                    <small>
                                        &copy; A long time ago in a galaxy far, far away through {moment().format("LL")} &copy; Copyright {SettingsModel.SiteName}
                                    </small>
                                </span>
                                <span className="text-white text-end col">
                                    <small>
                                        <a href="/read/privacy-policy">Privacy</a> | <a href="/read/terms-of-service">Terms &amp; Service</a>
                                    </small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <script src="https://kit.fontawesome.com/41bbadf5c3.js" crossOrigin="anonymous"></script>
                </>
            );
        }
    }
}