import React from 'react';
import moment from 'moment';
import { getAwards } from '../api/resume';
import { AwardsLoading } from '../elements/awardsLoading';

export class Award extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            awards: [],
            awardsTitle: props.awardsTitle,
            resumeId: props.resumeId,
        };
    }
    componentDidMount() {
        getAwards(this.state.resumeId)
            .then((a) => {
                this.setState({
                    isLoaded: true,
                    awards: a
                });
            },
            (error) => { 
                console.error(error);
                this.setState({
                    isLoaded: false,
                    error
                });
            });
    }
    render() {
        if(this.state.error)
        {
            return <div>Error: {this.state.error}</div>
        }
        else if(!this.state.isLoaded)
        {
            return (
                <>
                    <AwardsLoading count="2"></AwardsLoading>
                </>
            );
        }
        else 
        {
            return (
                <section className="resume">
                    <h3 className="mt-4">{this.state.awardsTitle ? this.state.awardsTitle : "Awards"}</h3>
                    <hr className="bg-primary my-2" style={{backgroundColor: "Red"}} />
                    <div className="row">
                        {this.state.awards.map((award) => (
                            <figure key={award.awardId}>
                                <blockquote className="blockquote">
                                    <h5 className="small">{award.title}<br /></h5>
                                    <p className="small">{award.summary}</p>
                                </blockquote>
                                <figcaption className="blockquote-footer float-end">
                                    {award.nominatedBy ? <small className={`small ${this.props.textColor}`}>{award.nominatedBy}<br /></small> : ""}
                                    {award.nominated ? <small className={`small ${this.props.textColor}`}>Nominated: {moment(award.nominated).format('MMMM YYYY')} {'  '}</small>: ""}
                                    {award.awarded ? <small className={`small ${this.props.textColor}`}>Awarded: {moment(award.awarded).format('MMMM YYYY')}</small> : ""}
                                </figcaption>
                            </figure>
                        ))}
                    </div>
                </section>
            );
        }
    }
}
