import React from 'react';
import { MainLayout } from '../../../components/layout';
import { searchContents } from '../api/Contents';
import { searchResumes } from '../api/Resumes';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-duotone-svg-icons';
const toast = require('toastr');

export class SearchPage extends React.Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(window.location.search);
        const st = query.get('searchTerm');
        this.state = {
            isLoaded: false,
            searchTerm: st,
            searchResults: [],
            contentResults: [],
            contentLoaded: false,
            resumeResults: [],
            resumeLoaded: false,
        };
//this.setState({searchTerm: st});
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.displayResults = this.displayResults.bind(this);
        toast.options.positionClass = "toast-bottom-left";
    }
    componentDidMount() {
        if(this.state.searchTerm !== '')
        {
            this.handleSubmit(null);
        }
    }
    handleChange(event){
        var name = event.target.id;
        var value = event.target.value;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {console.log(window.location);
        searchContents(this.state.searchTerm).then((results) => this.setState({ contentLoaded: true, contentResults: results }, this.displayResults), (error) => this.setState({ isLoaded: false, error }));
        searchResumes(this.state.searchTerm).then((results) => this.setState({ resumeLoaded: true, resumeResults: results }, this.displayResults), (error) => this.setState({ isLoaded: false, error }));
        if(event !== null)
        {
            event.preventDefault();
        }
    }

    displayResults()
    {
        if(this.state.contentLoaded === true && this.state.resumeLoaded === true)
        {
            window.history.pushState({}, document.title, "/search?searchTerm=" + this.state.searchTerm);
            this.setState({
                searchResults: [...this.state.resumeResults, ...this.state.contentResults],
                isLoaded: true,
            });
        }
    }

    render() {
        if(this.state.isLoaded)
        {
        return (
            <MainLayout>
                <div className="container bg-white py-5 mb-5">
                    <div className="row">
                        <form onSubmit={this.handleSubmit} id="searchForm" className='col-md-6'>
                            <input id="Token" type="hidden" />
                            <div validation-summary="ModelOnly" className="text-danger"></div>
                            <div className="form-floating mb-4 input-group">
                                <input placeholder="_" id="searchTerm" className="form-control border border-secondary border-end-0" value={this.state.searchTerm} onChange={this.handleChange} />
                                <label htmlFor="searchTerm">Term</label>
                                <button type="submit" className="btn btn-light border border-secondary btn-lg btn-block border-start-0"><FontAwesomeIcon icon={faMagnifyingGlass} className="text-secondary"/></button>
                            </div>
                        </form>
                    </div>
                    <div className="mb-3 p-3">{this.state.searchResults.sort((a, b) => a.rank > b.rank ? 1 : 0).map((item) => (
                        <div key={item.id} className="mb-4">
                            <a href={item.url} title={item.title} className="d-block">{window.location.origin+item.url}</a>
                            <h5 className="d-inline-block">
                                <a href={item.url} title={item.title} className="text-reset text-decoration-none">{item.title}</a>
                            </h5>
                            <span className={'badge rounded-pill align-text-bottom ms-3 text-bg-'+item.type}>{item.type}</span> 
                            <p><small>{moment(item.created).fromNow()}</small>&nbsp;&#8212;&nbsp;{item.description}</p>
                        </div>
                    ))}</div>
                </div>
            </MainLayout>
        )
        } else {
            return (
            <MainLayout>
                <div className="container bg-white py-5 mb-5 min-vh-75">
                    <div className='row align-items-center'>
                        <div className="">
                            <form onSubmit={this.handleSubmit} id="searchForm" className='col-md-6 offset-md-3'>
                                <input id="Token" type="hidden" />
                                <div validation-summary="ModelOnly" className="text-danger"></div>
                                <div className="form-floating mb-4 input-group">
                                    <input placeholder="_" id="searchTerm" className="form-control border border-secondary border-end-0" value={this.state.searchTerm} onChange={this.handleChange} />
                                    <label htmlFor="searchTerm">Start your search here.</label>
                                    <button type="submit" className="btn btn-light border border-secondary btn-lg btn-block border-start-0"><FontAwesomeIcon icon={faMagnifyingGlass} className="text-secondary"/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="mb-3 p-3">
                    </div>
                </div>
            </MainLayout>
            )
        }
    }
}