export class AnalyticsSettings {
    FacebookAppID;
    GoogleTrackingID;
    MicrosoftTrackingID;
}

export class CorporateSettings {
    CompanyName;
    Street1;
    Street2;
    City;
    State;
    Zip;
    Country;
}
export class EmailSettings {
    EmailAddress;
    Signature;
}
export class GeneralSettings {
    CaptchaSiteKey;
    Email;
    HasCommerce;
    CommerceBrowseCategory;
    LandingImageId;
    Logo;
    LogoLight;
    Moto;
    Notification;
    PhoneNumber;
    SiteName;
    //TinyMCEKey;
    URL;
}

export class NotificationSettings {
    AllowSiteVisitNotification;
}

export class PaymentSettings {
    Name;
    Vendor;
    IsTestMode = false;
}

export class ShippingSettings {
    ShippingType;
    Cost;
    Weight;
    HandlingCharge;
    CombineShippingHandling;
    WeightInPounds;
}

export class SocialSettings {
    AmazonAddress;
    FacebookAddress;
    InstagramAddress;
    TwitterAddress;
    PinterestAddress;
    TumblrAddress;
    FlickrAddress;
    LinkedInAddress;
    YouTubeAddress;
    FoursquareAddress;
    GooglePlusAddress;
    YelpAddress;
}

export class SettingsModel {
    AnalyticsSettings = new AnalyticsSettings();
    CorporateSettings = new CorporateSettings();
    EmailSettings = new EmailSettings();
    GeneralSettings = new GeneralSettings();
    PaymentSettings = new PaymentSettings();
    NotificationSettings = new NotificationSettings();
    ShippingSettings = new ShippingSettings();
    SocialSettings = new SocialSettings();
}

export default SettingsModel;