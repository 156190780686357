import {Header} from '../components/header';
import {Experience} from '../components/experience';
import {Summary} from '../components/summary';
import {Award} from '../components/awards';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { SettingsModel } from '../../settings';
import { Education } from '../components/educations';

export function Plain(resume)
{
    var localResume = resume.resume;
    return (
        <div>
            {/* <Helmet>
                <title>{localResume.name}</title>
                <meta name="robots" content="index, follow" />
                <meta name="author" content={localResume.createdBy} />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={localResume.name} />
                <meta name="twitter:creator" content={localResume.createdBy} />
                <meta name="twitter:site" content={SettingsModel.URL} />


                <meta name="og:url" content={SettingsModel.URL+"/read/"+localResume.safeName} />
                <meta name="og:type" content="resume" />
                <meta name="og:title" content={localResume.name} />
                <meta name="og:description" content={localResume.metaDescription} />
            </Helmet> */}
            <div className='text-center'>
                <a href='/'><img src={localResume.imageUrl} className="my-3 img-fluid mw-50" alt='My Logo'></img></a>
            </div>
            <div className='text-center'><Header title={localResume.name} subtitle={localResume.subtitle} summary={localResume.summary} street1={localResume.street1} city={localResume.city} state={localResume.state} postalCode={localResume.postalCode} phoneNumber={localResume.phoneNumber} email={localResume.email}/></div>
            <Summary summary={localResume.summary} summaryTitle={localResume.summaryTitle} />
            <Experience experiences={localResume.experiences} experienceTitle={localResume.experienceTitle} resumeId={localResume.resumeId} />
            <Award awards={localResume.awards} awardsTitle={localResume.awardsTitle} resumeId={localResume.resumeId} />
            <Education educationTitle={localResume.educationTitle} resumeId={localResume.resumeId} />
            <small>Updated: {moment(localResume.created).fromNow()}</small>
        </div>
    );
}