import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server'
import {ContactEmailHtml, ContactEmailText} from '../../../components/notification/email/contact.template';
const toast = require('toastr');
const sendgrid = require('../api/sendgrid');

export class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            Email: '',
            PhoneNumber: '',
            Comment: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        toast.options.positionClass = "toast-bottom-left";
    }

    handleChange(event){
        var name = event.target.id;
        var value = event.target.value;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        const msg = {
            to: process.env.SENDGRID_RECIPIENT,
            from: process.env.SENDGRID_SENDER,
            subject: `Contact Message From: ${this.state.Name}`,
            html: renderToStaticMarkup(<ContactEmailHtml name={this.state.Name} email={this.state.Email} phone={this.state.PhoneNumber} comment={this.state.Comment}/>),
            text: renderToStaticMarkup(<ContactEmailText name={this.state.Name} email={this.state.Email} phone={this.state.PhoneNumber} comment={this.state.Comment}/>),
        }
        if(sendgrid.send(msg))
        {
            toast.success("Your message has been sent!");
        }else{
            toast.error("There was an error sending your request.  Please try again later.");
        }
        event.preventDefault();
        event.target.reset();
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit} id="contactForm">
                    <input id="Token" type="hidden" />
                    <div validation-summary="ModelOnly" className="text-danger"></div>
                    <div className="form-floating mb-4">
                        <input placeholder="_" id="Name" className="form-control" value={this.state.Name} onChange={this.handleChange} />
                        <label htmlFor="Name">Name</label>
                        <span validation-for="Name" className="invalid-feedback"></span>
                    </div>
                    <div className="form-floating mb-4">
                        <input placeholder="_" id="Email" className="form-control" value={this.state.Email} onChange={this.handleChange} />
                        <label htmlFor="Email">Email</label>
                        <span asp-validation-for="Email" className="invalid-feedback"></span>
                    </div>
                    <div className="form-floating mb-4">
                        <input placeholder="_" id="PhoneNumber" className="form-control" value={this.state.PhoneNumber} onChange={this.handleChange} />
                        <label htmlFor="PhoneNumber">Phone Number</label>
                        <span asp-validation-for="PhoneNumber" className="invalid-feedback"></span>
                    </div>
                    <div className="form-floating mb-4">
                        <textarea placeholder="_" id="Comment" className="form-control" style={{"height": "14rem"}} value={this.state.Comment} onChange={this.handleChange} />
                        <label htmlFor="Comment">Comment</label>
                        <span asp-validation-for="Comment" className="invalid-feedback"></span>
                    </div>
                    <div className="mb-3">
                        <button type="submit" className="btn btn-primary btn-lg btn-block">Submit Message</button>
                    </div>
                </form>
            </div>
        )
    }
}