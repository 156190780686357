import React from "react";
import { MainFooter } from "../../features/footer";
import { MainHeader } from "../../features/header";
//import '../../site.scss';
import 'toastr/toastr.scss';

export const MainLayout = ( {children} ) => {
    return (
        <>
            <MainHeader></MainHeader>
            <main className="min-vh-75">
                {children}
            </main>
            <MainFooter></MainFooter>
        </>
    );
}