import React, { useEffect, useRef } from "react";
import { ContactForm } from "../../contact";
import { FeaturedItems } from "../../front-page";
import {Parallax} from "react-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import image from "../assets/me-filtered-blur-bw.jpg";
import girlsonbeach from "../assets/girls-on-beach.jpg";
import { faDollarSign, faFileSignature, faMusic, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { MainLayout } from "../../../components/layout";
import { Helmet } from "react-helmet";
import { SettingsModel } from "../../settings";
export function MainLanding () {
        return (
            <MainLayout>
                <Helmet>
                    <title>{SettingsModel.SiteName}</title>
                    <meta name="robots" content="index, follow" />
                    <meta name="author" content={SettingsModel.CompanyName} />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={SettingsModel.SiteName} />
                    <meta name="twitter:creator" content={SettingsModel.CompanyName} />
                    <meta name="twitter:site" content={SettingsModel.URL} />

                    <meta name="og:url" content={SettingsModel.URL} />
                    <meta name="og:type" content="Article" />
                    <meta name="og:title" content={SettingsModel.SiteName} />
                    <meta name="og:description" content={SettingsModel.Moto} />
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css"/>
                </Helmet>
                <section className="min-vh-100 w-100 d-flex align-items-center" style={{backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                    <div className="container-fluid my-5">
                        <div className="row">
                            <div className="card p-4 offset-md-8 col-md-3 my-auto" data-aos="fade-left" data-aos-duration="3000">
                                <div className="card-body border border-primary border-3 text-black-50 text-center rounded">
                                    <div className="text-center pb-4">
                                        <img src='/img/name-horizontal-logo.png' alt="Brandon Korous Logo" className="img-fluid w-50"/>
                                    </div>
                                    <h1>A leading technologist and mentor focusing on creating platforms and processes for delivery.</h1>
                                    <p>Are you looking to get to market ahead of your competition?<br/>
                                    Are you looking to release more frequently?<br/>
                                    Are you looking to do more faster?</p>
                                    <p>Contact me to find out how I can assist your organization.</p>
                                    <ContactForm/>
                                    <h3 className="pt-4">Not sure what I can do for you?</h3>
                                    <p className="pt-3">
                                        Find out more by visiting my online resume.
                                    </p>
                                    <a href="resume/cloud-technology-leader" className="btn btn-outline-resume" target="_blank">Online Resume!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="min-vh-25 text-center bg-primary py-5 text-white d-flex align-items-center">
                    <div className="container">
                        <FeaturedItems count="1" type="Article" link="false" card="false"/>
                    </div>
                </section>
                <section className="min-vh-50 py-5 d-flex align-items-center">
                    <div className="container">
                        <FeaturedItems count="4" type="Blog" link="true" card="true"/>
                    </div>
                </section>
                <section className="min-vh-25 d-flex align-items-center py-5 bg-secondary text-white">
                    <div className="container text-center">
                        <h2>Career</h2>
                        <p>
                            Currently I am working on restructuring the entire infrastructure for a company rated by Forbes to be in the top 5 of world companies.  This is a wholistic replacement of legacy applications and datastores that have been built for decades.
                        </p>
                        <h4>I haven't always been in technology, that would be too simple.</h4>
                        <div className="row">
                            <div className="col-md-4 text-start my-4">
                                <div className="d-flex align-items-center h-100">
                                    <div className="flex-shrink-0">
                                        <FontAwesomeIcon icon={faDollarSign} size="6x" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="text-tertiary">Sales</h4>
                                        <p className="fs-5">I have sales experience in the Mobile, Telecommunication, Business Services, and Automobile industries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-start my-4">
                                <div className="d-flex align-items-center h-100">
                                    <div className="flex-shrink-0">
                                        <FontAwesomeIcon icon={faUsers} size="6x" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="text-tertiary">Management</h4>
                                        <p className="fs-5">I have had the priviledge to manage some great personnel over the years.  Teams have ranged from sales to technology, from onsite to overseas, from small to large.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-start my-4">
                                <div className="d-flex align-items-center h-100">
                                    <div className="flex-shrink-0">
                                        <FontAwesomeIcon icon={faMusic} size="6x" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="text-tertiary">Radio DJ</h4>
                                        <p className="fs-5">As odd as it sounds, it's true.  I have even been a Radio DJ.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4>
                            I don't want to spend too much time on these subject here, but feel free to check out my resume 
                            <a href="/resume/cloud-technology-leader" className="btn btn-primary btn-lg m-4 text-light"><FontAwesomeIcon icon={faFileSignature} />&nbsp;Live Resume</a><br/> or visit my LinkedIn<a className="btn btn-outline-tertiary btn-lg m-4" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/brandonkorous"><FontAwesomeIcon icon={faLinkedin} />&nbsp;LinkedIn</a>
                        </h4>
                    </div>
                </section>
                <section id="scenic-section" className="min-vh-50">
                    <Parallax bgImage={girlsonbeach} bgImageAlt="" strength={500}>
                        <div className="vh-50 d-flex align-items-center p-5">
                            <div className="container">
                                <h1 className="text-primary text-center">
                                    Every so often, I step away from the technology and enjoy nature.
                                </h1>
                                <h4 className="text-white text-center">
                                    It's okay to follow me, I don't mind.
                                </h4>
                                <div className="d-flex justify-content-evenly">
                                    <a href="https://www.instagram.com/brandonkorous/"><FontAwesomeIcon icon={faInstagram} size="6x" /></a>
                                    <a href="https://www.twitter.com/brandonkorous/"><FontAwesomeIcon icon={faTwitter} size="6x" /></a>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                </section>
                <section className="bg-white">
                    <div className="text-center p-5">
                        <h2 className="container">
                            Current Technologies<br />
                        </h2>
                        <h4>I have an extensive list of technologies that I have directly worked with.  Here is a list of the most common technologies that I find myself using currently.</h4>
                        <h3 className="text-primary py-4">Coding</h3>
                        <div className="container">
                            <div className="well row">
                                <div className="col fa-4x devicon-dot-net-plain-wordmark text-hover-secondary p-3" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-content=".Net"></div>
                                <i className="col fa-4x devicon-dotnetcore-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-csharp-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-typescript-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-express-original text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-react-original text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-nodejs-plain text-hover-secondary p-3"></i>
                                {
                                    //<i className="col fa-4x devicon-terraform-plain text-hover-secondary p-3"></i>
                                }
                                <i className="col fa-4x devicon-html5-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-css3-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-less-plain-wordmark text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-sass-original text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-javascript-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-jquery-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-docker-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-microsoftsqlserver-plain text-hover-secondary p-3"></i>
                            </div>
                        </div>
                        <hr />
                        <h3 className="text-primary py-4">Interfaces</h3>
                        <div className="container">
                            <div className="well row">
                                <i className="col fa-4x devicon-github-original text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-git-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-visualstudio-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-vscode-plain-wordmark text-hover-secondary p-3"></i>
                                {
                                    //<i className="col fa-4x devicon-xcode-plain text-hover-secondary p-3"></i>
                                }
                                {
                                    //<i className="col fa-4x devicon-androidstudio-plain text-hover-secondary p-3"></i>
                                }
                                <i className="col fa-4x devicon-chrome-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-trello-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-slack-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-windows8-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-apple-plain text-hover-secondary p-3"></i>
                            </div>
                        </div>
                        <hr />
                        <h3 className="text-primary py-4">Design</h3>
                        <div className="container">
                            <div className="well row">
                                <i className="col fa-4x devicon-illustrator-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-photoshop-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-canva-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-bootstrap-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-foundation-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-materialui-plain text-hover-secondary p-3"></i>
                            </div>
                        </div>
                        <hr />
                        <h3 className="text-primary py-4">Platforms</h3>
                        <div className="container">
                            <div className="well row">
                                <i className="col fa-4x devicon-azure-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-amazonwebservices-plain text-hover-secondary p-3"></i>
                                <i className="col fa-4x devicon-android-plain text-hover-secondary p-3"></i>
                                <span className="col fa-4x devicon-auth0 text-hover-secondary p-3"></span>
                                <i className="col fa-4x devicon-kubernetes-plain text-hover-secondary p-3"></i>
                            </div>
                        </div>
                        <hr />
                        <h3 className="text-primary py-4">Other Stuff</h3>
                        <div className="container">
                            <div className="well row">
                                <i className="col fa-2x devicon-markdown-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-drupal-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-wordpress-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-c-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-java-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-python-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-php-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-objectivec-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-d3js-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-digitalocean-plain text-hover-secondary p-3"></i>
                                {
                                    //<i className="col fa-2x devicon-ifttt-original text-hover-secondary p-3"></i>
                                }
                                <i className="col fa-2x devicon-mysql-plain text-hover-secondary p-3"></i>
                                {
                                    //<i className="col fa-2x devicon-sqlite-plain text-hover-secondary p-3"></i>
                                }
                                <i className="col fa-2x devicon-postgresql-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-nginx-plain text-hover-secondary p-3"></i>
                                <i className="col fa-2x devicon-salesforce-plain text-hover-secondary p-3"></i>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-secondary min-vh-50 d-flex align-items-center p-5">
                    <div className="container text-right">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="text-white">
                                    I'm always up for a new challenge.
                                </h2>
                                <h3 className="">Have a need or a question?</h3>
                                <h4 className="">Need someone who can do everything with technology?</h4>
                                <p>Whether it's a question about my experience, or a need for a consultant, use this form and I will do my best to meet your needs.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="justify-content-center">
                                    <ContactForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <script>
                </script>
            </MainLayout>
        )
}