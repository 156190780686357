export async function searchResumes(term) {
    let terms = term.toLowerCase().split(' ');
    let newResults = [];
    var results = await fetch("/api/resume/search?term="+term).then((obj) => obj.json()).then((obj) => {
        for(const result of obj)
        {
            result.rank = 0;
            for(let t of terms)
            {
                result.rank += (result.name.toLowerCase().match(t)||[]).length;
                result.rank += (result.summary?.toLowerCase().match(t)||[]).length;
                result.rank += (result.footnote?.toLowerCase().match(t)||[]).length;
                //result.rank += (result.body?.toLowerCase().match(t)||[]).length;
            }
            newResults.push({
                id: result.resumeId,
                title: result.name,
                url: "/resume/"+result.safeName,
                description: result.summary,
                created: result.created,
                type: "resume",
                rank: result.rank
            });
        };
        return newResults;
    }).catch((ex) => console.error("search Error: " + ex));
    return results;
}