export async function send(msg) {
    var result = await fetch("/api/sendgrid/send",
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(msg)
    })
    .then((obj) => {return true;})
    .catch((ex) => {console.error("get Error: " + ex); return false;});
    return result;
}