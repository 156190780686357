const getItems = () => {
    let items = [];
    items.push(
        <div className="col d-flex align-items-start px-4 placeholder-glow">
            <div className="w-100">
                <h1 className="fs-2"><span className="placeholder bg-content w-50 display-1"></span></h1>
                <h2 className="text-muted"><span className="placeholder bg-content w-25"></span></h2>
                <p className="p-3"></p>
                <p className="">
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-75 bg-content"></span>
                </p>
                <p className="">
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-25 bg-content"></span>
                </p>
                <p className="">
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-50 bg-content"></span>
                </p>
                <p className="">
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-25 bg-content"></span>
                </p>
                <p className="">
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-75 bg-content"></span>
                </p>
                <p className="">
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-100 bg-content"></span>
                    <span className="placeholder w-50 bg-content"></span>
                </p>
            </div>
        </div>
    );
    return items;
}
export const Loading = () => {
    return (
        <div className="container bg-white">
            {
                getItems()
            }
        </div>
    );
}