import React from 'react';
import { useParams } from 'react-router';
import { getContents } from '../api/Contents';
import moment from 'moment';
import { MainLayout } from '../../../components/layout';
import { Helmet } from 'react-helmet';
import { SettingsModel } from '../../settings';
import 'masonry-layout';

export function ScrollContent() {
    let {safeTitle} = useParams();
    return (
        <>
            {<ScrollContentDetails safeTitle={safeTitle}/>}
        </>
    );
};

export class ScrollContentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            contents: [],
        };
    }

    componentDidMount() {
        getContents(this.props.safeTitle)
            //.then(result => {console.log(result); return result.json();})
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    contents: result
                });
            },
            (error) => {
                console.error(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    }

    sortContent(a, b) {
        return a.created > b.created ? -1 : 1;
    }
    render() {
        
        if(this.state.contents.length > 0)
        {
            return (
                <MainLayout>
                    <Helmet>
                        <title>{"Scroll Content ~ " + SettingsModel.SiteName}</title>
                        <meta name="robots" content="index, follow" />
                        <meta name="keywords" content="Blogs,Articles,Recipes,Content,Read,Learn" />
                        <meta name="description" content="Scroll the content of this website." />
                        <meta name="author" content={SettingsModel.CompanyName} />

                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content="Scroll Content"/>
                        <meta name="twitter:creator" content={SettingsModel.CompanyName} />
                        <meta name="twitter:description" content="Scroll the content of this website." />
                        <meta name="twitter:site" content={SettingsModel.URL} />


                        <meta name="og:url" content={SettingsModel.URL+"/browse"} />
                        <meta name="og:type" content="articles" />
                        <meta name="og:title" content="Scroll Content" />
                        <meta name="og:description" content="Scroll the content of this website." />
                    </Helmet>
                    <div className="container py-5 bg-white">
                        <div className="px-4">
                            {this.state.contents.sort((a, b) => this.sortContent(a, b)).map(content => (
                                <div key={content.contentId} className="row">
                                    <h1 className="display-1">{content.title}</h1>
                                    <h4 className="text-muted">{content.subTitle}</h4>
                                    <div dangerouslySetInnerHTML={{__html: content.body}}></div>
                                    <div className='text-muted small mb-4'>Updated: {moment(content.created).fromNow()}</div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    
                    </div>
                </MainLayout>
            );
        }
        return null;
    }
};
