import React from 'react';

function HeaderWithSubtitle(title, subtitle)
{
    return (
        <div className="page-header">
            <h1 className="display-4">{title}</h1>
            <h4>{subtitle}</h4>
        </div>
    );
}
function HeaderWithoutSubtitle(title)
{
    return (
        <div className="page-header">
            <h1 className="display-4">{title}</h1>
        </div>
    );
}

export class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            title: props.title,
            subtitle: props.subtitle,
            street1: props.street1,
            street2: props.street2,
            city: props.city,
            state: props.state,
            zip: props.postalCode,
            phoneNumber: props.phoneNumber,
            email: props.email,
        };
    }

    render() {

        if(this.state.subtitle)
        {
            return (
                <>
                {HeaderWithSubtitle(this.state.title, this.state.subtitle)}
                <div>
                    <p>
                        {this.state.city} {this.state.state} {this.state.postalCode}<br />
                        <a href={"tel:"+this.state.phoneNumber}>{this.state.phoneNumber}</a><br />
                        <a href={"mailto:"+this.state.email}>{this.state.email}</a>
                    </p>
                </div>
                </>
            )
        }
        else
        {
            return (
                <>
                {HeaderWithoutSubtitle(this.state.title)}
                <div>
                    <p>
                        {this.state.city} {this.state.state} {this.state.postalCode}<br />
                        <a href={"tel:"+this.state.phoneNumber}>{this.state.phoneNumber}</a><br />
                        <a href={"mailto:"+this.state.email}>{this.state.email}</a>
                    </p>
                </div>
                </>
            )
        }
    }
};

//export default ReadContent;