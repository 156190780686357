import { getSettings } from "../api/Settings";
import { SettingsModel } from "../types/settings.model";
export const Settings = () => {

    getSettings()
    .then((items) => {console.log(items.find(i => i.type === 'GeneralSettings' && i.name === 'Logo')?.value);
        SettingsModel.AllowSiteVisitNotification = items.find(i => i.type === 'NotificationSettings' && i.name === 'AllowSiteVisitNotification')?.value;
        
        SettingsModel.Logo = items.find(i => i.type === 'GeneralSettings' && i.name === 'Logo')?.value;
        SettingsModel.LogoLight = items.find(i => i.type === 'GeneralSettings' && i.name === 'LogoLight')?.value;
        SettingsModel.CaptchaSiteKey = items.find(i => i.type === 'GeneralSettings' && i.name === 'CaptchaSiteKey')?.value;
        SettingsModel.SiteName = items.find(i => i.type === 'GeneralSettings' && i.name === 'SiteName')?.value;
        SettingsModel.Moto = items.find(i => i.type === 'GeneralSettings' && i.name === 'Moto')?.value;
        SettingsModel.URL = items.find(i => i.type === 'GeneralSettings' && i.name === 'URL')?.value;
        SettingsModel.Email = items.find(i => i.type === 'GeneralSettings' && i.name === 'Email')?.value;

        SettingsModel.Street1 = items.find(i => i.type === 'CorporateSettings' && i.name === 'Street1')?.value;
        SettingsModel.Street2 = items.find(i => i.type === 'CorporateSettings' && i.name === 'Street2')?.value;
        SettingsModel.City = items.find(i => i.type === 'CorporateSettings' && i.name === 'City')?.value;
        SettingsModel.State = items.find(i => i.type === 'CorporateSettings' && i.name === 'State')?.value;
        SettingsModel.Zip = items.find(i => i.type === 'CorporateSettings' && i.name === 'Zip')?.value;
        SettingsModel.Country = items.find(i => i.type === 'CorporateSettings' && i.name === 'Country')?.value;

        SettingsModel.AmazonAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'AmazonAddress')?.value;
        SettingsModel.FacebookAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'FacebookAddress')?.value;
        SettingsModel.FlickrAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'FlickrAddress')?.value;
        SettingsModel.FoursquareAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'FoursquareAddress')?.value;
        SettingsModel.InstagramAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'InstagramAddress')?.value;
        SettingsModel.GooglePlusAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'GooglePlusAddress')?.value;
        SettingsModel.LinkedInAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'LinkedInAddress')?.value;
        SettingsModel.PinterestAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'PinterestAddress')?.value;
        SettingsModel.TumblrAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'TumblrAddress')?.value;
        SettingsModel.TwitterAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'TwitterAddress')?.value;
        SettingsModel.YelpAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'YelpAddress')?.value;
        SettingsModel.YouTubeAddress = items.find(i => i.type === 'SocialSettings' && i.name === 'YouTubeAddress')?.value;
    },
    (error) => {
        console.log("error loading settings");
    });
}

export default Settings;