import React from 'react';
import { useParams } from 'react-router';
import { getContent } from '../api/Contents';
import { Helmet } from 'react-helmet';
import { SettingsModel } from '../../settings';
import { MainLayout } from '../../../components/layout';
import { Loading } from './loading';

export function ReadContent() {
    let {safeTitle} = useParams();
    return (
        <>
            {<ReadContentDetails safeTitle={safeTitle}/>}
        </>
    );
};

export class ReadContentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            content: null,
        };
    }

    componentDidMount() {
        getContent(this.props.safeTitle)
            .then((c) => {
                this.setState({
                    isLoaded: true,
                    content: c
                });
            },
            (error) => { 
                console.error(error);
                this.setState({
                    isLoaded: false,
                    error
                });
            });
    }
    doCodeFormatting() {
        var localdiv = document.createElement('div');
        localdiv.innerHTML = this.state.content.body;
        var pres = localdiv.querySelectorAll('pre');
        if(pres.length > 0)
        {
            pres.forEach(pre => {
                var codes = pre.querySelectorAll('code');
                if(codes.length > 0)
                {
                    codes.forEach(code => {

                        if(pre.classList.contains("language-csharp"))
                        {
                            const cSharpKeywords = ['abstract','as','async','await','base','bool','break','byte','case','catch','char','checked','class','const','continue','decimal','default','delegate','do','double','dynamic','else','enum','event','explicit','extern','false','finally','fixed','float','for','foreach','goto','if','implicit','in','int','interface','internal','is','lock','long','namespace','new','null','object','operator','out','override','params','private','protected','public','readonly','ref','return','sbyte','sealed','short','sizeof','stackalloc','static','string','struct','switch','this','throw','true','try','typeof','uint','ulong','unchecked','unsafe','ushort','using','var','virtual','void','volatile','while'];
                            const cSharpClasses = ['Object','Ok','PagedModel','PagedList','Expression','LambdaBuilder','String','DateTime','TimeSpan', 'Guid', 'Exception', 'List', 'Dictionary', 'HashSet', 'Queue', 'Stack', 'Tuple', 'Task', 'StringBuilder', 'XmlDocument', 'XmlReader', 'XmlWriter'];
                            const cSharpInterfaces = ['IActionResult','IHttpHandler','IHttpModule','IHttpAsyncHandler', 'IAsyncResult', 'IIdentity', 'IPrincipal', 'IController', 'IActionFilter', 'IAuthorizationFilter', 'IExceptionFilter', 'IResultFilter', 'IDependencyResolver'];
        
                            cSharpKeywords.map(key => 
                                code.innerHTML = code.innerHTML.replace(new RegExp('\\b'+key+'\\b(?!=)', 'gs'), "<span class='text-keyword'>"+key+"</span>")
                            );
                            cSharpClasses.map(key => 
                                code.innerHTML = code.innerHTML.replace(new RegExp('\\b'+key+'\\b(?!=)', 'gs'), "<span class='text-class'>"+key+"</span>")
                            );
                            cSharpInterfaces.map(key => 
                                code.innerHTML = code.innerHTML.replace(new RegExp('\\b'+key+'\\b(?!=)', 'gs'), "<span class='text-interface'>"+key+"</span>")
                            );
                        }
                        if(pre.classList.contains("language-markup"))
                        {
                            var html = code.innerHTML;
                            const propregex = /(\w+(?:-[\w]+)*?)=["']?(?![<])((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/gi;
                            html = html.replace(propregex, '<span class="text-interface">$1</span>="$2"');
                            const openregex = /(&lt;|<)([\w-]+)(.*?)(&gt;|\/>|&gt;[\s\S]*?&lt;\/\2&gt;)/gi;
                            html = html.replace(openregex, '$1<span class="text-keyword">$2</span>$3>');
                            const closeregex = /((&lt;|<)\/?)([\w-]+)(&gt;|\/>)/gi;
                            html = html.replace(closeregex, '&lt;/<span class="text-keyword">$3</span>&gt;');
                            //const commentregex = /\/\/.+?$|\/\*[\s\S]+?\*\/|<!--\s*(.*?)\s*-->/gm;
                            const commentregex = /<!--\s*(.*?)\s*-->/gi;
                            html = html.replace(commentregex, '<span class="text-keyword">$1</span>');
                            code.innerHTML = html;
                        }
                        var bracketWords = code.innerHTML.match(new RegExp('\\[(.*?)\\]', 'gs'));
                        bracketWords?.forEach(key => 
                            code.innerHTML = code.innerHTML.replace(new RegExp('\\'+key+'', 'gs'), "<span class='text-keyword'>"+key+"</span>")
                        );
                    } );
                }
                
            });
            return localdiv.innerHTML;
        }
        return this.state.content.body;
    }
    render() {
        if(this.state.isLoaded === false || this.state.content === null)
        {
            return (
                <MainLayout>
                    <Loading />
                </MainLayout>
            );
        }
        if(this.state.content !== null)
        {
            return (
                <MainLayout>
                    <Helmet>
                        <title>{this.state.content.title}</title>
                        <meta name="robots" content="index, follow" />
                        <meta name="keywords" content={this.state.content.metaKeywords} />
                        <meta name="description" content={this.state.content.metaDescription} />
                        <meta name="author" content={this.state.content.createdBy} />

                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content={this.state.content.title} />
                        <meta name="twitter:creator" content={this.state.content.createdBy} />
                        <meta name="twitter:description" content={this.state.content.metaDescription} />
                        <meta name="twitter:site" content={SettingsModel.URL} />


                        <meta name="og:url" content={SettingsModel.URL+"/read/"+this.state.content.safeTitle} />
                        <meta name="og:type" content="article" />
                        <meta name="og:title" content={this.state.content.title} />
                        <meta name="og:description" content={this.state.content.metaDescription} />
                    </Helmet>
                    <div className="container bg-white py-5 mb-5">
                        <h1 className="display-4">{this.state.content.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: this.doCodeFormatting()}}></div>
                    </div>
                </MainLayout>
            );
        }
        return null;
    }
};

//export default ReadContent;