import React, { useMemo, useEffect, useState } from 'react';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import { getBotToken } from '../api/bot';
import { faComments } from '@fortawesome/pro-duotone-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function WebChatTest() {
    const [token, setToken] = useState();
    const [chatOpen, setChatOpen] = useState(false);
    const directLine = useMemo(() => createDirectLine({ token }), [token]);

    const onchat = () => {
        setChatOpen(!chatOpen);
    };

    useEffect(() => {
        const response = getBotToken().then((obj) => { setToken(obj.token); });

    }, []);

    return (
        <div className='position-fixed end-0 bottom-0 h-25 d-flex flex-column-reverse h-50'>
            <div>
                <span className='fa-layers fa-fw fa-5x float-end text-secondary my-3 me-3' onClick={onchat}>
                    <FontAwesomeIcon icon={faCircle} className='shadow rounded-circle'/>
                    <FontAwesomeIcon icon={faComments} transform="shrink-6" fixedWidth beat inverse />
                </span>
            </div>
            <div className={`border border-secondary border-5 me-3 shadow flex-fill ${chatOpen ? "d-block" : "d-none"}`}><ReactWebChat directLine={directLine} /></div>
        </div>
    );
};
