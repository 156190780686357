import React from 'react';
import { useParams } from 'react-router';
import { getProduct } from '../api/Products';
import { Helmet } from 'react-helmet';
import { SettingsModel } from '../../settings';
import { MainLayout } from '../../../components/layout';
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { Loading } from './loading';

export function ShopProduct() {
    let {safeName} = useParams();
    return (
        <>
            {<ShopProductDetails safeName={safeName}/>}
        </>
    );
};

export class ShopProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            product: null,
        };
    }

    componentDidMount() {
        getProduct(this.props.safeName)
            .then((p) => {console.log(p);
                this.setState({
                    isLoaded: true,
                    product: p
                });
            },
            (error) => { 
                console.error(error);
                this.setState({
                    isLoaded: false,
                    error
                });
            });
    }

    formatMoney(number) {
        return '$'+number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    render() {
        if(this.state.isLoaded === false || this.state.product === null)
        {
            return (
                <MainLayout>
                    loading...
                </MainLayout>
            );
        }
        if(this.state.content !== null)
        {
            return (
                <MainLayout>
                    <Helmet>
                        <title>{this.state.product.name}</title>
                        <meta name="robots" content="index, follow" />
                        <meta name="keywords" content={this.state.product.metaKeywords} />
                        <meta name="description" content={this.state.product.metaDescription} />
                        <meta name="author" content={this.state.product.createdBy} />

                        <meta name="twitter:card" content="description" />
                        <meta name="twitter:title" content={this.state.product.name} />
                        <meta name="twitter:creator" content={this.state.product.createdBy} />
                        <meta name="twitter:description" content={this.state.product.metaDescription} />
                        <meta name="twitter:site" content={SettingsModel.URL} />


                        <meta name="og:url" content={SettingsModel.URL+"/shop/"+this.state.product.safeName} />
                        <meta name="og:type" content="product" />
                        <meta name="og:title" content={this.state.product.title} />
                        <meta name="og:description" content={this.state.product.metaDescription} />
                    </Helmet>
                    <div className="container bg-white py-5 mb-5">
                        <div className='row'>
                            <div className='col-12 col-md-6'></div>
                            <div className='col-12 col-md-6'>
                                <h1 className="display-4">{this.state.product.displayName}</h1>
                                <h3>{this.formatMoney(this.state.product.msrp)}</h3>
                                <div dangerouslySetInnerHTML={{__html: this.state.product.description}}></div>
                                <button className="btn btn-primary text-white">Add to Cart<FontAwesomeIcon icon={faShoppingCart} className='ms-2' /></button>
                            </div>
                        </div>
                    </div>
                </MainLayout>
            );
        }
        return null;
    }
};

//export default ReadContent;