import React from 'react';
import moment from 'moment';
import { getExperiences } from '../api/resume';
import { ExperienceLoading } from '../elements/experienceLoading';

export class Experience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            experienceTitle: props.experienceTitle,
            experiences: [],
            resumeId: props.resumeId,
            accentColor: props.accentColor
        };
    }
    componentDidMount() {
        getExperiences(this.state.resumeId)
            .then((e) => {
                this.setState({
                    isLoaded: true,
                    experiences: e
                });
            },
            (error) => { 
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    }

    sortExperience(a, b) {
        var da = new Date(a.startDate).getTime();
        var db = new Date(b.startDate).getTime();
        return db - da;
    }

    render() {
        if(this.state.error)
        {
            return <div>Error: {this.state.error}</div>
        }
        else if(!this.state.isLoaded)
        {
            return (
                <>
                    <ExperienceLoading count="5"></ExperienceLoading>
                </>
            );
        }
        else 
        {
            return(
                <section className="resume">
                    <h3 className="mt-4">{this.state.experienceTitle ? this.state.experienceTitle : "Experience"}</h3>
                    <hr className="bg-primary my-2"/>
                    <div className="something">
                        {this.state.experiences.sort((a, b) => this.sortExperience(a, b)).map((experience) => (
                            <div className="py-4"  key={experience.experienceId}>
                                <h5>{experience.title} <small className="fst-italic float-end">({moment(experience.startDate).format('MMMM YYYY')} - {experience.endDate == null ? "Present" : moment(experience.endDate).format('MMMM YYYY')})</small></h5>
                                <h6 className="font-weight-light">{experience.companyName}<small className=""> - {experience.location}</small></h6>
                                <div id="experience-{{experience.ExperienceId}}">
                                    <p>{experience.Description}</p>
                                    <ul>
                                        {experience.items.map((item) => 
                                            <li key={item.experienceItemId}>{item.description}</li>
                                        )}
                                    </ul>
                                    <div>
                                        {experience.tagLinks.map((tag) => 
                                            <span className={'badge bg-primary text-white'} key={tag.ExperienceTagId}>{tag.tag.title}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            );
        }
    }
};
