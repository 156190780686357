import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {v4 as uuid } from 'uuid';
import { MainLayout } from '../../../components/layout';

export class RandomNamePicker extends Component{
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            names: [],
            name: "",
            pickedName: "",
            prize: "",
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.pickName = this.pickName.bind(this);
        this.handlePrizeInput = this.handlePrizeInput.bind(this);
        this.handlePrizeLinkInput = this.handlePrizeLinkInput.bind(this);
    }

    componentDidMount() {
        
    }

    handleInput(event) {
        this.setState({name: event.target.value});
    }
    handlePrizeInput(event) {
        this.setState({prize: event.target.value});
    }
    handlePrizeLinkInput(event) {
        this.setState({prizeLink: event.target.value});
    }

    handleSubmit(event) {
        var names = this.state.names;
        let name = {
            id: uuid(),
            name: this.state.name,
        }
        names.push(name);
        this.setState({names: names});
        this.setState({name: ""});
    }
    
    pickName(event) {
        var names = this.state.names;
        var length = names.length;
        var index = Math.floor(Math.random() * (length));
        var name = names[index];
        this.setState({pickedName: name.name});
        //event.preventDefault();
    }

    render() {
        return (
            <MainLayout>
                <Helmet></Helmet>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='input-group mb-5'>
                                        <div className='form-floating'>
                                            <input className='form-control' type='textbox' title='Name' onChange={this.handleInput} value={this.state.name} />
                                            <label className='form-label'>Name</label>
                                        </div>
                                        <input className='btn btn-primary' type='button' value='Add' onClick={this.handleSubmit}/>
                                    </div>
                                    <h4 className='card-title'>Name List:</h4>
                                    {this.state.names.length <= 0 ?
                                        <p>No names have been added to the list yet.</p>
                                    :
                                        <ul>
                                        {this.state.names.map((name) => {
                                            return (
                                            <li key={name.id}>{name.name}</li>
                                            );
                                        })}
                                        </ul>
                                    }
                                    {this.state.names.length > 0 ?
                                            <>
                                                <div className='form-floating mb-4'>
                                                    <input className='form-control' type='textbox' title='Prize' onChange={this.handlePrizeInput} />
                                                    <label className='form-label'>Prize Title</label>
                                                </div>
                                                <div className='form-floating mb-4'>
                                                    <input className='form-control' type='textbox' title='PrizeLink' onChange={this.handlePrizeLinkInput} />
                                                    <label className='form-label'>Prize Link</label>
                                                </div>
                                                <input className='btn btn-secondary w-100' type='button' value='Pick Winner' onClick={this.pickName} />
                                            </>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            {this.state.pickedName === '' ?
                            <></>
                            :
                            <div className='card border-success'>
                                <div className='card-body'>
                                    <div className='container'>
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <h1 className='text-center'>
                                                    CONGRATULATIONS
                                                </h1>
                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <h1 className='text-center'>
                                                    {this.state.pickedName}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <h3 className='text-center'>
                                                    YOU HAVE BEEN SELECTED AS THE WINNER OF:
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <h1 className='text-center'>
                                                    {this.state.prize}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <h5 className='text-center'>
                                                    It is with great honor that I have the opportunity to be able to grant this award.  I certainly hope that you get to fully enjoy the full experience of your winnings!
                                                </h5>
                                            </div>
                                        </div>
                                        {this.state.prizeLink === '' ?
                                        <></>
                                        :
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <p className='text-center'>
                                                    <a href={this.state.prizeLink} title='Prize Link'>{this.state.prizeLink}</a>
                                                </p>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </MainLayout>
        )
    }
}