import React, {useMemo} from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import './site.scss';
import { ReadContent, BrowseContent } from './features/content/';
import {ReadResume, PrintResume} from './features/resume/';
import { MainLanding } from './features/landing';
import {Settings, SettingsModel} from './features/settings';
import { ShopProduct } from './features/product';
import {Helmet} from "react-helmet";
import "@popperjs/core";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ContactPage } from './features/contact';
import { BrowseResumes } from './features/resume/components/browse';
import { ScrollContent } from './features/content/';
import { RandomNamePicker } from './features/random-name-picker/components/picker';
import { SearchPage } from './features/search';
import {WebChatTest} from './features/webchat';

function App() {
    Settings();
    return (
      <>
        <Helmet>
          <title>{SettingsModel.SiteName}</title>
          <script src="https://kit.fontawesome.com/41bbadf5c3.js" crossorigin="anonymous"></script>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path="/search" element={<SearchPage/>} />
            <Route path="/shop/:safeName" element={<ShopProduct />} />
            <Route path="/random-name-picker" element={<RandomNamePicker />} />
            <Route path="/read/:safeTitle" element={<ReadContent />}/>
            <Route path="/browse/:safeTitle" element={<BrowseContent />}/>
            <Route path="/scroll/:safeTitle" element={<ScrollContent />}/>
            <Route path="/resume/print/:safeName" element={<PrintResume/>}/>
            <Route path="/resume/:safeName" element={<ReadResume/>}/>
            <Route path="/resume/browse" element={<BrowseResumes/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/" element={<MainLanding/>} />
          </Routes>
        </BrowserRouter>
        {/* <MinimizableWebChat/> */}
        <WebChatTest />
      </>
    );
}

export default App;
