

export const ExperienceLoading = ({ count }) => {
    var placeholders = [];

    for (var i=0; i<count; i++)
    {
        placeholders.push(
            <div className="py-4" key={"experience-"+i}>
                <p className="placeholder-wave opacity-25">
                    <h3 className="placeholder col-4 me-4 bg-resume">&npsp;</h3>
                    <span className="placeholder col-4 float-end bg-resume">&npsp;</span>
                    <br/>
                    <h5 className="placeholder col-3 me-4 bg-resume">&nbsp;</h5>
                </p>
                <ul>
                    
                </ul>
                <ul className="placeholder-wave opacity-25">
                    <li className="pb-2">
                        <span className="placeholder col-12 bg-resume">&npsp;</span>
                        <span className="placeholder col-12 bg-resume">&npsp;</span>
                        <span className="placeholder col-4 bg-resume">&npsp;</span>
                    </li>
                    <li className="pb-2">
                        <span className="placeholder col-12 bg-resume">&npsp;</span>
                        <span className="placeholder col-2 bg-resume">&npsp;</span>
                    </li>
                    <li className="pb-2">
                        <span className="placeholder col-12 bg-resume">&npsp;</span>
                        <span className="placeholder col-12 bg-resume">&npsp;</span>
                        <span className="placeholder col-12 bg-resume">&npsp;</span>
                        <span className="placeholder col-5 bg-resume">&npsp;</span>
                    </li>
                </ul>
            </div>
        );
    }
    

    return (
        <>
            <p className="placeholder-wave opacity-25 resume">
                <h3 className="mt-4 placeholder col-4 me-4 bg-resume">&npsp;</h3>
                <hr className="bg-primary my-2"/>
            </p>
            {placeholders.map(p => { return p })}
        </>
    );
}