import React from "react";
//import '../../site.scss';
import 'toastr/toastr.scss';


export const PlainLayout = ( {children} ) => {
    return (
        <>
            <main className="min-vh-75">
                {children}
            </main>
        </>
    );
}