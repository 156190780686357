import React from 'react';
import { useParams } from 'react-router';
import { getContents } from '../api/Contents';
import moment from 'moment';
import { MainLayout } from '../../../components/layout';
import { Helmet } from 'react-helmet';
import { SettingsModel } from '../../settings';
import 'masonry-layout';

export function BrowseContent() {
    let {safeTitle} = useParams();
    return (
        <>
            {<BrowseContentDetails safeTitle={safeTitle}/>}
        </>
    );
};

export class BrowseContentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            contents: [],
        };
    }

    componentDidMount() {
        getContents(this.props.safeTitle)
            //.then(result => {console.log(result); return result.json();})
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    contents: result
                });
            },
            (error) => {
                console.error(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    }
    render() {
        
        if(this.state.contents.length > 0)
        {
            return (
                <MainLayout>
                    <Helmet>
                        <title>{"Browse Content ~ " + SettingsModel.SiteName}</title>
                        <meta name="robots" content="index, follow" />
                        <meta name="keywords" content="Blogs,Articles,Recipes,Content,Read,Learn" />
                        <meta name="description" content="Browse the content of this website." />
                        <meta name="author" content={SettingsModel.CompanyName} />

                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content="Browse Content"/>
                        <meta name="twitter:creator" content={SettingsModel.CompanyName} />
                        <meta name="twitter:description" content="Browse the content of this website." />
                        <meta name="twitter:site" content={SettingsModel.URL} />


                        <meta name="og:url" content={SettingsModel.URL+"/browse"} />
                        <meta name="og:type" content="articles" />
                        <meta name="og:title" content="Browse Content" />
                        <meta name="og:description" content="Browse the content of this website." />
                    </Helmet>
                    <div className="container py-5 bg-white">
                        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3" data-masonry='{"percentPosition": true }'>
                            {this.state.contents.map(content => (
                                <div className="col d-flex align-items-start px-4 pb-5" key={content.contentId}>
                                    <div>
                                        <h2 className="fs-2">{content.title}</h2>
                                        <h4 className="text-muted">{content.subTitle}</h4>
                                        <p className="">{content.intro}</p>
                                        <div className='text-muted small mb-4'>Updated: {moment(content.created).fromNow()}</div>
                                        <div className='d-flex justify-content-end'>
                                            <a href={'/read/' + content.safeTitle} className="btn btn-content">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    
                    </div>
                </MainLayout>
            );
        }
        return null;
    }
};
