import React from 'react';
import { getFeatured } from '../api/Featured';
import {Loading} from './loading';
import moment from 'moment';
//import masonry from 'masonry';
//import '../../../../node_modules/masonry-layout/dist/masonry.pkgd';

export class FeaturedItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            frontPageItems: [],
            columns: 12,
        };
    }

    componentDidMount() {
        var columns = 12/this.props.count;
        getFeatured(this.props.type, this.props.count)
            .then((frontPageItems) => {
                this.setState({
                    isLoaded: true,
                    frontPageItems: frontPageItems?.value
                });
            },
            (error) => {
                console.error(error);
                this.setState({
                    isLoaded: false,
                    columns: columns,
                    error
                });
            });
    }
    
    render() {
        const { error, isLoaded, frontPageItems } = this.state;
        if(error) {
            return <div>Error: {error}</div>;
        } else if (!isLoaded) {
            return <Loading count={this.props.count}/>;
        }else {
            if(frontPageItems && frontPageItems.length > 0)
            {

                return (
                    <div className="container">
                        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3 justify-content-evenly">
                            {frontPageItems.map((fp) => (
                                <div className="flex-fill" key={fp.content.contentId}>
                                {this.props.card === "true" ?
                                    <div className="col d-flex align-items-start px-4" key={fp.content.contentId}>
                                        <div>
                                            <h2 className="fs-2">{fp.content.title}</h2>
                                            <h4 className="text-muted">{fp.content.subTitle}</h4>
                                            <p className="">{fp.content.intro}</p>
                                            <div className='text-muted small mb-4'>Updated: {moment(fp.content.created).fromNow()}</div>
                                            {this.props.link === "true" ? 
                                                (
                                                    <a href={'/read/'+fp.content.safeTitle} className="btn btn-content">Read More</a>
                                                ): ""
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="h-100" >
                                        <h2>{fp.content.title}</h2>
                                        <h4 className="text-muted">{fp.content.subTitle}</h4>
                                        <p className="py-3">{fp.content.intro}</p>
                                        {this.props.link === "true" ? 
                                            (
                                                <a href={'/read/'+fp.content.safeTitle} className="btn btn-content">Read More</a>
                                            ): ""
                                        }
                                    </div>
                                }
                                </div>
                            ))}
                        </div>
                    
                    </div>
                );
            }
        }
        return null;
    }
};
