export async function getContent(title) {
    var result = await fetch("/api/content/content?title="+title).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    if(result.length > 0)
    {
        return result[0];
    }
    return null;
}

export async function getContents(title) {
    var result = await fetch("/api/content/contents?title="+title).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    return result;
}