import React from 'react';
import { Helmet } from 'react-helmet';
import { MainLayout } from '../../../components/layout';
import {getResumes} from '../api/resume';
import moment from 'moment';

export function BrowseResumes() {
    return (
        <>
            {<BrowseResumeDetails />}
        </>
    );
};

export class BrowseResumeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            resumes: [],
        }
    }

    componentDidMount() {
        getResumes().then((results) => {
            this.setState({
                isLoaded: true,
                resumes: results
            });
        },
        (error) => {
            console.error(error);
            this.setState({
                isLoaded: true,
                error
            });
        });
    }
    
    render() {
        return (
            <MainLayout>
                <Helmet>

                </Helmet>
                <div className='container py-5'>
                        <div className="row row-cols-1 row-cols-md-3 rows-cols-lg-4 g-4" data-masonry='{"percentPosition": true }'>
                            {this.state.resumes.map((resume) => (
                                <div className="col" key={resume.resumeId}>
                                    <div className="card shadow border-resume h-100" >
                                        <div className="card-body">
                                            <h2 className="card-title">{resume.name}</h2>
                                            <div className="card-text my-5">{resume.summary}</div>
                                            <a href={'/resume/' + resume.safeName} className="btn btn-resume">Read More</a>
                                        </div>
                                        <div className="card-footer" data-hover-showable>
                                            <div>Updated: {moment(resume.created).fromNow()}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <script src="../../../../node_modules/masonry-layout/dist/masonry.pkgd.min.js"></script>
                </div>
            </MainLayout>
        );
    }
}