export async function searchContents(term) {
    let terms = term.toLowerCase().split(' ');
    let newResults = [];
    var results = await fetch("/api/content/search?term="+term).then((obj) => obj.json()).then((obj) => {
        for(const result of obj)
        {
            result.rank = 0;
            for(let t of terms)
            {
                result.rank += (result.title.toLowerCase().match(t)||[]).length;
                result.rank += (result.intro?.toLowerCase().match(t)||[]).length;
                result.rank += (result.body?.toLowerCase().match(t)||[]).length;
            }
            newResults.push({
                id: "content-"+result.contentId,
                title: result.title,
                url: "/read/"+result.safeTitle,
                description: result.intro,
                created: result.created,
                type: "content",
                rank: result.rank
            });
        };
        return newResults;
    }).catch((ex) => console.error("search Error: " + ex));
    return results;
}