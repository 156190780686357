

export const SummaryLoading = ({ count }) => {
    var placeholders = [];

    for (var i=0; i<count; i++)
    {
        placeholders.push(
            <>
                <p className="placeholder-wave opacity-25">
                    <span className="placeholder col-4 me-4 bg-resume">&npsp;</span>
                    <span className="placeholder col-3 me-4 bg-resume">&npsp;</span>
                    <span className="placeholder col-4 bg-resume">&npsp;</span>
                </p>
            </>
        );
    }
    

    return (
        <>
            <p className="placeholder-wave opacity-25 resume">
                <h3 className="mt-4 placeholder col-4 me-4 bg-resume">&npsp;</h3>
                <hr className="bg-primary my-2"/>
            </p>
            {placeholders.map(p => { return p })}
        </>
    );
}