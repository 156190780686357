const getItems = (count) => {
    let items = [];
    for(let i = 0; i< count; i++)
    {
        items.push(
            <div className="col d-flex align-items-start px-4 placeholder-glow" key={"key-"+i}>
                <div className="w-100">
                    <h2 className="fs-2"><span className="placeholder bg-content w-100"></span></h2>
                    <h4 className="text-muted"><span className="placeholder bg-content w-50"></span></h4>
                    <p className="">
                        <span className="placeholder w-50 bg-content"></span>
                        <span className="placeholder w-25 bg-content"></span>
                        <span className="placeholder w-50 bg-content"></span>
                        <span className="placeholder w-75 bg-content"></span>
                    </p>
                    <div className='text-muted small mb-4'><span className="placeholder bg-content w-50"></span></div>
                    <button className="btn btn-content disabled placeholder">Read More</button>
                </div>
            </div>
        );
    }
    return items;
}
export const Loading = ( count ) => {
    return (
        <div className="d-flex align-items-center">
            {getItems(count)}
        </div>
    );
}