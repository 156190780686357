import {Header} from '../components/header';
import {Experience} from '../components/experience';
import {Summary} from '../components/summary';
import {Award} from '../components/awards';
import moment from 'moment';
import { Education } from '../components/educations';

export function Sidebar(resume)
{
    var localResume = resume.resume;
    return (
        <div className="row">
            <div className="col-lg-3 col-md-4 pt-2 pb-5" style={{backgroundColor: localResume.accentColor, color: localResume.accentTextColor}}>
                <img src="/img/name-horizontal-light.svg" className="my-3 img-fluid" alt='My Logo'></img>
                <Header title={localResume.name} subtitle={localResume.subtitle} summary={localResume.summary} street1={localResume.street1} city={localResume.city} state={localResume.state} postalCode={localResume.postalCode} phoneNumber={localResume.phoneNumber} email={localResume.email}/>
                <Summary summary={localResume.summary} summaryTitle={localResume.summaryTitle} />
                <Award awards={localResume.awards} awardsTitle={localResume.awardsTitle} resumeId={localResume.resumeId} textColor="text-light" />
                <Education educationTitle={localResume.educationTitle} resumeId={localResume.resumeId} />
            </div>
            <div className="col-lg-9 col-md-8 pb-5">
                <Experience experiences={localResume.experiences} experienceTitle={localResume.experienceTitle} resumeId={localResume.resumeId} accentColor={localResume.accentColor} />
                <small>Updated: {moment(localResume.created).fromNow()}</small>
            </div>
        </div>
    );
}