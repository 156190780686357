import React from 'react';
import moment from 'moment';
import { getEducations } from '../api/resume';
import { ExperienceLoading } from '../elements/experienceLoading';

export class Education extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            educationTitle: props.educationTitle,
            educations: [],
            resumeId: props.resumeId,
            accentColor: props.accentColor
        };
    }
    componentDidMount() {
        getEducations(this.state.resumeId)
            .then((e) => {
                this.setState({
                    isLoaded: true,
                    educations: e
                });
            },
            (error) => { 
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    }

    render() {
        if(this.state.error)
        {
            return <div>Error: {this.state.error}</div>
        }
        else if(!this.state.isLoaded)
        {
            return (
                <>
                    <ExperienceLoading count="2"></ExperienceLoading>
                </>
            );
        }
        else 
        {
            return(
                <section className="resume">
                    <h3 className="mt-4">{this.state.educationTitle ? this.state.educationTitle : "Education"}</h3>
                    <hr className="bg-primary my-2"/>
                    <div className="">
                        {this.state.educations.map((education) => (
                            <div className="py-4"  key={education.educationId}>
                                <h5>{education.title}</h5>
                                <h6 className="font-weight-light">{education.organization}<small className=""> - {education.location}</small></h6>
                                <small className="fst-italic">({moment(education.startDate).format('MMMM YYYY')} - {education.endDate == null ? "Present" : moment(education.endDate).format('MMMM YYYY')})</small>
                                <p>{education.description}</p>
                            </div>
                        ))}
                    </div>
                </section>
            );
        }
    }
};
