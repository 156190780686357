import React from 'react';
export function ContactEmailHtml(name, email, phone, comment) {
    return(
        <div>
            Hello, this is a message from brandonkorous.com.

            {name ?  
                <p>{name} submitted a contact form on brandonkorous.com</p>
                :
                <p>Someone submitted a contact form on brandonkorous.com</p>
            }

            Here are the details of the message:
            Name: {name}<br/>
            Email: {email}<br/>
            Phone: {phone}<br/>
            Comment:<br/>
            {comment}

        </div>
    )
}
export function ContactEmailText(name, email, phone, comment) {
        return (<>
        Hello, this is a message from brandonkorous.com.<br/>
        {name ?  
            <>{name} submitted a contact form on brandonkorous.com<br/></>
            :
            <>Someone submitted a contact form on brandonkorous.com<br/></>
        }

        Here are the details of the message:<br/>
        Name: {name}<br/>
        Email: {email}<br/>
        Phone: {phone}<br/>
        Comment:<br/>
        {comment}
        </>);
            
}