export async function getResumes() {
    var results = await fetch("/api/resume/resumes").then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    return results;
}
export async function getResume(safeName) {
    var result = await fetch("/api/resume/resume?safeName="+safeName).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    if(result.length > 0)
    {
        return result[0];
    }
    return null;
}

export async function getExperiences(resumeId) {
    var result = await fetch("/api/resume/experiences?resumeId="+resumeId).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    return result;
}
export async function getAwards(resumeId) {
    var result = await fetch("/api/resume/awards?resumeId="+resumeId).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    return result;
}
export async function getEducations(resumeId) {
    var result = await fetch("/api/resume/educations?resumeId="+resumeId).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    return result;
}

// export async function getResumes(safeName) {
//     var result = await fetch("/api/resume/resume?safeName="+safeName).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
//     return result;
// }

export default getResumes;