export async function getProduct(name) {
    var result = await fetch("/api/product/product?name="+name).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    if(result.length > 0)
    {
        return result[0];
    }
    return null;
}

export async function getProducts(name) {
    var result = await fetch("/api/product/products?name="+name).then((obj) => obj.json()).catch((ex) => console.error("get Error: " + ex));
    return result;
}