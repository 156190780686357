import React from "react";
import { getLinks } from "../api/getLinks";
import { SettingsModel } from '../../settings';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";

export class MainHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            searchTerm: "",
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        getLinks("Title Navigation")
            .then((items) => {
                this.setState({
                    isLoaded: true,
                    items: items
                });
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        var navbar = document.getElementsByClassName('navbar')[0];
        if(navbar){
            if(window.scrollY >= 100) {
                navbar.classList.add('shadow');
                navbar.classList.add('bg-light');
            } else {
                navbar.classList.remove('shadow');
                navbar.classList.remove('bg-light');
            }
        }
    }

    handleSearchChange(event) {
        var name = event.target.id;
        var value = event.target.value;
        this.setState({
            [name]: value,
        });
    }

    handleSearch(event) {
        window.location.href = "/search?searchTerm=" + this.state.searchTerm;
        event.preventDefault();
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if(error) {
            return <div>Error: {error}</div>;
        } else if (!isLoaded) {
            return (
                <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src={SettingsModel.Logo} alt={SettingsModel.SiteName} height="55" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 placeholder-glow">
                                <li className="placeholder col-2"></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                );
        }else {
            return (
                <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src={SettingsModel.Logo} alt={SettingsModel.SiteName} height="55" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar" aria-controls="main-navbarr" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="main-navbar">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li>
                                    <form id="searchForm" className="me-3" role="search" onSubmit={this.handleSearch}>
                                        <input id="Token" type="hidden" />
                                        <div validation-summary="ModelOnly" className="text-danger"></div>
                                        <div className="input-group">
                                            <input placeholder="Start your search here." id="searchTerm" className="form-control border border-secondary border-end-0" value={this.state.searchTerm} onChange={this.handleSearchChange} />
                                            <button type="submit" className="btn btn-sm btn-light border border-secondary btn-lg btn-block border-start-0"><FontAwesomeIcon icon={faMagnifyingGlass} className="text-secondary" fixedWidth/></button>
                                        </div>
                                    </form>
                                </li>
                                {items.map((item) => (
                                    <li key={item.linkId} className="nav-item">
                                        <a href={item.path} className={item.cssClass + " nav-link"}>{item.title}{item.iconClass === null ? "" : <i className={item.iconClass + " ps-2"}></i>}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </nav>
            )
        }
    }
}