import React from 'react';
import { MainLayout } from '../../../components/layout';
import { ContactForm } from './main';


export class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <MainLayout>
                <div className="container py-5 mb-5 bg-white">
                    <div className="row">
                        <div className='col-md-6'>
                            <h4>Not clear if or how I can assist your organization?</h4>
                            <ul>
                                <li>Are you looking to get to market ahead of your competition?</li>
                                <li>Releasing more frequently?</li>
                                <li>Do more faster?</li>
                                <li>Pushing your organization into new technologies?</li>
                                <li>The cloud on your horizon?</li>
                            </ul>
                            <p>I am the right fit for any of these, and many many more technology related objectives.</p>
                            <p className="pt-3">
                                Want to see more of the ways I have helped other organizations, visit my online resume.
                            </p>
                            <a href="resume/cloud-technology-leader" className="btn btn-outline-resume me-2" target="_blank">Online Resume!</a>
                            <p></p>
                            <p>
                                You can also reach out to me at: <a href='mailto:m@brandonkorous.com'>me@brandonkorous.com</a>
                            </p>
                        </div>
                        <div className='col-md-6'>
                            <h1>Feel Free To Contact Me!</h1>
                            <p>Contact me to find out how I can assist your organization.</p>
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </MainLayout>
        )
    }
}