import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { PlainLayout } from '../../../components/layout';
import { SettingsModel } from '../../settings';
import { getResume } from '../api/resume';
import {Plain} from '../layout/plain';

export function PrintResume() {
    let {safeName} = useParams();
    return (
        <>
            {<PrintResumeDetails safeName={safeName}/>}
        </>
    );
};

export class PrintResumeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            resume: null,
        };
    }

    componentDidMount() {
        getResume(this.props.safeName)
            .then((r) => {  
                this.setState({
                    isLoaded: true,
                    resume: r
                });
            },
            (error) => { 
                console.error(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    }
    render() {
        if(this.state.resume)
        {
            return (
                <PlainLayout>
                    <Helmet>
                        <title>{this.state.resume.name}</title>
                        <meta name="robots" content="index, follow" />
                        <meta name="author" content={this.state.resume.createdBy} />

                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content={this.state.resume.name} />
                        <meta name="twitter:creator" content={this.state.resume.createdBy} />
                        <meta name="twitter:site" content={SettingsModel.URL} />


                        <meta name="og:url" content={SettingsModel.URL+"/read/"+this.state.resume.safeName} />
                        <meta name="og:type" content="resume" />
                        <meta name="og:title" content={this.state.resume.name} />
                        <meta name="og:description" content={this.state.resume.metaDescription} />
                    </Helmet>
                    <div className="container-lg container-fluid bg-white">
                        <Plain resume={this.state.resume}/>
                    </div>
                </PlainLayout>
            );
        }
        return null;
    }
};

//export default ReadContent;