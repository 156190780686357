

export const AwardsLoading = ({ count }) => {
    var placeholders = [];

    for (var i=0; i<count; i++)
    {
        placeholders.push(
            <div key={"awards-"+i}>
                <p className="placeholder-wave opacity-25">
                    <span className="placeholder col-4 me-4 bg-resume">&npsp;</span>
                    <span className="placeholder col-3 me-4 bg-resume">&npsp;</span>
                    <span className="placeholder col-4 bg-resume">&npsp;</span>
                </p>
                <p className="placeholder-wave opacity-25">
                    <span className="placeholder col-5 me-4 bg-resume">&npsp;</span>
                    <span className="placeholder col-2 me-4 bg-resume">&npsp;</span>
                    <span className="placeholder col-3 bg-resume">&npsp;</span>
                </p>
                <p className="placeholder-wave opacity-25 justify-content-end pb-4">
                    <span className="placeholder ms-auto col-2 float-end bg-resume small">&npsp;</span>
                    <span className="placeholder col-4 bg-resume float-end me-4 small">&npsp;</span>
                </p>
            </div>
        );
    }
    

    return (
        <>
            <p className="placeholder-wave opacity-25 resume">
                <h3 className="mt-4 placeholder col-4 me-4 bg-resume">&npsp;</h3>
                <hr className="bg-primary my-2"/>
            </p>
            {placeholders.map(p => { return p })}
        </>
    );
}